const getProperLangDocument = (documents, lang) => {

  let properDocument = documents[0].node.content;

  for (let document of documents) {

    if (lang === document.node.language) {
      properDocument = document.node.content;
      break;
    }
  }

  return properDocument;
};

export default getProperLangDocument;
