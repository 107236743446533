import * as React from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Layout from '../../components/layout/layout';
import './style/legal.css';
import getProperLangDocument from './components/proper-lang';
import Seo from '../../components/seo';
import getCurrentTranslations from '../../components/contentful-translator';
import ogImage from '../../images/seo/SEO_fundacja.jpg';

const RodoPage = ({ data }) => {
  const { language } = useI18next();
  const { t } = useTranslation();
  const rodoObjects = getCurrentTranslations(data.rodoObjects.edges, language);
  const properRodo = getProperLangDocument(rodoObjects, language);

  return (
    <Layout>
      <Seo
        title={t('rodo.page-title')}
        description={t('rodo.page-description')}
        ogImage={ogImage}
      />
      <section className="page-section container legal" id="rodo">
        {renderRichText(properRodo)}
      </section>
    </Layout>
  );
};

export default RodoPage;
