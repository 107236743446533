import { css } from 'styled-components';

export const breakpoints = {
  xs: '100px',
  s: '568px',
  m: '1124px',
  l: '1324px',
  xl: '1824px'
};

export const respondTo = Object.entries(breakpoints).reduce(
  (breakpoints, [key, value]) => {
    breakpoints[key] = (...args) => css`
      @media (min-width: ${value}) {
        ${css(...args)};
      }
    `;
    return breakpoints;
  },
  {}
);
